import axios, { AxiosResponse } from 'axios'

const employeesForgotPassword = async (data: ForgotPasswordPayload): Promise<AxiosResponse> => axios.post('/employees/password', data)
const employeesResetPassword = async (data: ResetPasswordPayload): Promise<AxiosResponse> => axios.put('/employees/password', data)

const updateUserEmailApi = async (data: { email: string | any }): Promise<AxiosResponse> => axios.put('/employees', data)
const checkEmailDuplicate = async (data: any): Promise<AxiosResponse> => axios.post('/employee/registered_check', data)

const employeesServices = {
  employeesForgotPassword,
  employeesResetPassword,
  updateUserEmailApi,
  checkEmailDuplicate,
}

export default employeesServices

type ForgotPasswordPayload = {
  family_name: string
  given_name: string
  email: string
}

type ResetPasswordPayload = {
  password: string
  password_confirmation: string
  reset_password_token?: string
}
